import { render, staticRenderFns } from "./Payments.vue?vue&type=template&id=7dc9a740&scoped=true"
import script from "./Payments.vue?vue&type=script&lang=js"
export * from "./Payments.vue?vue&type=script&lang=js"
import style0 from "./Payments.vue?vue&type=style&index=0&id=7dc9a740&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc9a740",
  null
  
)

export default component.exports