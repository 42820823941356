<template>
<el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
        <div>
            <img src="../assets/logo.png" alt="">
            <span>梦视音乐·管理后台</span>
            <span>欢迎，{{userName}}! 今天是 {{sysDate}}</span>
        </div>
        <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主题区域 -->
    <el-container>
        <!-- 侧边栏 -->
        <el-aside :width="toogleColapse ? '64px':'200px'">
            <div class="toggle" @click="toggleCollapse">
                <i :class="toggleClass"></i>
            </div>
            <el-menu background-color="#303133" text-color="#fff" active-text-color="#F56C6C" :unique-opened="true" :collapse="toogleColapse" :collapse-transition="false" router :default-active="activePath">
                <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span>{{item.authName}}</span>
                    </template>
                    <el-menu-item :index="'/'+subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState('/'+subItem.path)">
                        <template slot="title">
                            <i :class="subItem.icon"></i>
                            <span>{{subItem.authName}}</span>
                        </template>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <!-- 侧边内容主题 -->
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
</el-container>
</template>

<script>
export default {
    created() {
        this.activePath = window.sessionStorage.getItem('activePath')
        this.getUserInfo()
    },
    data() {
        return {
            // 左侧菜单数据
            menuList: [{
                id: 90,
                authName: '首页',
                path: null,
                icon: 'iconfont icon-home',
                children: [{
                    id: 91,
                    authName: '数据统计',
                    path: 'welcome',
                    children: [],
                    icon: 'iconfont icon-statistics'
                }, {
                    id: 92,
                    authName: '收益明细',
                    path: 'income',
                    children: [],
                    icon: 'iconfont icon-statistics'
                }]
            }, {
                id: 100,
                authName: '内容管理',
                path: null,
                icon: 'iconfont icon-content',
                children: [{
                        id: 102,
                        authName: '歌曲',
                        path: 'songs',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 103,
                        authName: '视频',
                        path: 'videos',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 104,
                        authName: '电台',
                        path: 'fm/list',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 105,
                        authName: '广播',
                        path: 'radio',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 106,
                        authName: '歌单',
                        path: 'gedan',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 107,
                        authName: '课程',
                        path: 'cource',
                        children: [],
                        icon: 'el-icon-menu'
                    }, {
                        id: 108,
                        authName: '视频合集',
                        path: 'video/album',
                        children: [],
                        icon: 'el-icon-menu'
                    }
                ]
            }, {
                id: 600,
                authName: '分类管理',
                path: null,
                icon: 'iconfont icon-member',
                children: [{
                    id: 601,
                    authName: '内容分类',
                    path: 'category',
                    children: [],
                    icon: 'el-icon-menu'
                }]
            }, {
                id: 200,
                authName: '用户管理',
                path: null,
                icon: 'iconfont icon-member',
                children: [{
                    id: 201,
                    authName: '创作者',
                    path: 'artis',
                    children: [],
                    icon: 'iconfont icon-artis'
                }, {
                    id: 202,
                    authName: '用户管理',
                    path: 'users',
                    children: [],
                    icon: 'iconfont icon-artis'
                }]
            }, {
                id: 300,
                authName: '订单管理',
                path: null,
                icon: 'iconfont icon-order',
                children: [{
                    id: 301,
                    authName: '订单列表',
                    path: 'order',
                    children: [],
                    icon: 'el-icon-menu'
                }, {
                    id: 302,
                    authName: '购买列表',
                    path: 'payments',
                    children: [],
                    icon: 'el-icon-menu'
                }]
            }, {
                id: 500,
                authName: '运营',
                path: null,
                icon: 'iconfont icon-fin',
                children: [{
                    id: 501,
                    authName: '轮播图',
                    path: 'banner',
                    children: [],
                    icon: 'iconfont icon-rebate'
                }, {
                    id: 502,
                    authName: '菜单管理',
                    path: 'menus',
                    children: [],
                    icon: 'iconfont icon-withdraw'
                }, {
                    id: 505,
                    authName: '关于APP',
                    path: 'about',
                    children: [],
                    icon: 'iconfont icon-withdraw'
                }]
            }, {
                id: 900,
                authName: '系统设置',
                path: null,
                icon: 'iconfont icon-manage',
                children: [{
                    id: 901,
                    authName: '账号管理',
                    path: 'setting',
                    children: [],
                    icon: 'iconfont icon-setting'
                }, {
                    id: 902,
                    authName: 'VIP配置',
                    path: 'vip',
                    children: [],
                    icon: 'iconfont icon-vip'
                }]
            }, {
              id: 1000,
                authName: '应用中心',
                path: null,
                icon: 'iconfont icon-manage',
                children: [{
                    id: 1001,
                    authName: '版本升级',
                    path: 'app-center/upgrade',
                    children: [],
                    icon: 'iconfont icon-setting'
                }]
            }
            ],
            toogleColapse: false,
            toggleClass: 'iconfont icon-close',
            // 被激活的侧边栏连接
            activePath: '',
            userName: '-',
            sysDate: '-'
        }
    },
    methods: {
        logout() {
            window.sessionStorage.clear()
            this.$router.push('/signin')
        },

        toggleCollapse() {
            this.toogleColapse = !this.toogleColapse
            if (this.toogleColapse) {
                this.toggleClass = 'iconfont icon-open'
            } else {
                this.toggleClass = 'iconfont icon-close'
            }
        },
        // 保存连接的激活状况
        saveNavState(activePath) {
            window.sessionStorage.setItem('activePath', activePath)
            this.activePath = activePath
        },

        async getUserInfo() {
            const {
                data: result
            } = await this.$http.post('sysUser')
            if (result.code !== 200) {
                return this.$message.error(result.msg)
            }
            this.userName = result.data.user_name
            this.sysDate = result.data.sys_date
        }
    }
}
// {
            //     id: 400,
            //     authName: '财务',
            //     path: null,
            //     icon: 'iconfont icon-fin',
            //     children: [{
            //         id: 401,
            //         authName: '分成明细',
            //         path: 'rebates',
            //         children: [],
            //         icon: 'iconfont icon-rebate'
            //     }, {
            //         id: 402,
            //         authName: '提现明细',
            //         path: 'withdraw',
            //         children: [],
            //         icon: 'iconfont icon-withdraw'
            //     }]
            // },
</script>

<style lang="less" scoped>
.home-container {
    height: 100%;
}

.el-header {
    background-color: #222222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    color: #fff;

    div {
        display: flex;
        align-items: center;

        span {
            margin-left: 15px;
        }

        img {
            height: 44px;
            box-sizing: border-box;
            border-radius: 5px;
            margin-left: 15px;
        }
    }
}

.el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
}

.el-aside {
    background-color: #303133;

    .el-menu {
        border-right: none;
        color: #fff;
    }
}

.el-main {
    background-color: #eaedf1;
    width: 100%;
}

.iconfont {
    margin-right: 10px;
    font-size: 22px;
    color: #eee;
}

.toggle {
    background-color: #333;
    color: #909399;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.2em;
    cursor: pointer;
}
</style>
